<template>
  <div class="auth-first-step first-step">
    <form class="first-step__form">
      <ui-input
          v-model="getAuthForm.email"
          label="E-mail"
          required-field
          :error="$v.getAuthForm.email.$anyError || emailError"
          :error-text="getEmailErrorText"
          @input="emailError = false"
      />
      <ui-input
          v-model="getAuthForm.password"
          :label="$t('password')"
          show-password
          required-field
          :error="$v.getAuthForm.password.$anyError"
          :error-text="getPasswordErrorText"
      />
      <ui-input
          v-model="getAuthForm.repeatPassword"
          :label="$t('repeat-password')"
          show-password
          required-field
          :error="$v.getAuthForm.repeatPassword.$anyError"
          :error-text="getRepeatPasswordErrorText"
      />
    </form>
    <div class="first-step__action">
      <ui-button
          @click="submit"
          :disabled="$v.getAuthForm.$anyError || disabled"
      >
        {{ $t('continue') }}
      </ui-button>
      <router-link to="/login">
        <ui-button color="outline">{{ $t('log-in') }}</ui-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { required, sameAs, email, minLength  } from 'vuelidate/lib/validators'
import {mapGetters, mapActions} from "vuex";
  export default {
    name: 'AuthFirstStep',
    components: {
      UiInput: () => import("@/components/ui/UiInput"),
      UiButton: () => import("@/components/ui/UiButton"),
    },

    data() {
      return {
        emailError: false
      }
    },

    validations() {
      return {
        getAuthForm: {
          email: {
            required,
            email
          },
          password: {
            required,
            minLength: minLength(8)
          },
          repeatPassword: {
            required,
            sameAs: sameAs('password'),
            minLength: minLength(8)
          }
        }
      }
    },

    methods: {
      ...mapActions([
         'reqAuth'
      ]),
      submit() {
        this.$v.getAuthForm.$touch()
        if (!this.$v.getAuthForm.$anyError) {
          this.reqAuth({
            "email": this.getAuthForm.email,
          }).then(() => {
            this.$emit('success')
          }).catch((err) => {
            if(err.response.data.message === 'USER_WITH_THIS_EMAIL_ALREADY_EXISTS') {
              this.emailError = true
            }
          })
        }
      },
      enter(e) {
        if(e.keyCode === 13) {
          this.submit()
        }
      }
    },
    mounted() {
      document.addEventListener('keydown', this.enter)
    },
    computed: {
      ...mapGetters([
          'getAuthForm'
      ]),
      getEmailErrorText() {
        return !this.$v.getAuthForm.email.required ? 'E-mail' : '' ||
        !this.$v.getAuthForm.email.email ? this.$t('error-email') : '' ||
        this.emailError ? this.$t('email-already-exists') : ''
      },
      getPasswordErrorText() {
        return !this.$v.getAuthForm.password.required ? this.$t('password') : '' ||
        !this.$v.getAuthForm.password.minLength ? this.$t('error-short-password') : ''
      },
      getRepeatPasswordErrorText() {
        return !this.$v.getAuthForm.repeatPassword.required ? this.$t('repeat-password') : '' ||
        !this.$v.getAuthForm.repeatPassword.sameAs ? this.$t('error-password-do-not-match') : '' ||
        !this.$v.getAuthForm.repeatPassword.minLength ? this.$t('error-short-password') : ''
      },
      disabled() {
        return !this.getAuthForm.email || !this.getAuthForm.password || !this.getAuthForm.repeatPassword
      }
    }
  }
</script>

<style lang="scss" scoped>
.first-step {
  width: 100%;
  margin-top: 30px;

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #F5F5F5;;
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
